import React, { useEffect } from "react";
import PropertyWithFilter from "../src/components/PropertyWithFilter";
import Spotlight from "../src/components/Spotlight";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import StoriesData from "../src/components/Stories/StoriesData";
import withLayout from "../src/hoc/withLayout";
import { useProperty } from "../src/components/PropertyWithFilter/context";
import { useMediaQuery } from "@mui/material";
import theme from "../src/utils/theme";
import { event } from "../src/utils/gtm";
import { useTranslation } from "next-i18next";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { IMG_URL } from "../src/utils/utils";

export default function Home(props) {
  const { mapView } = useProperty();
  const mediumMatches = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation("common");
  const router = useRouter();
  useEffect(() => {
    event({
      action: "homepage_vistied",
      params: {}
    });
  }, []);

  const title = router.locale == 'en' ? 'Properties for Sale and Rent in Saudi Arabia | Sanadak Platform' : 'عقارات للبيع والإيجار في السعودية | منصة سندك العقارية';
  const description = router.locale === 'en' ? 'Platform for listing properties to sell and rent in Saudi Arabia, Search for your property in Riyadh, Jeddah, and other Saudi cities using the best search tools and an interactive map to find the best suitable apartment, villa, or land.' :'منصة عقارية لعرض العقارات للبيع او الايجار في السعودية، ابحث عن عقارك المفضل في الرياض وجدة وباقي مدن المملكة باستخدام أفضل قنوات البحث للوصول الى عقارك المطلوب (شقة، فيلا، ارض، استراحة)';
  const img = IMG_URL

  return (
    <React.Fragment>

      <NextSeo
        title={title}
        description={description}
        images={img}
        openGraph={{
            type: 'website',
            url: img,
            title: title,
            description: description,
            images: [
                {
                    url: img,
                    width: 800,
                    height: 600,
                    alt: title,
                },
            ],
        }}
        />
      
      {props.lang == "ar" ? 
        <h1 style={{opacity: 0, position: 'absolute'}}>عندك عقارات للبيع او للايجار في السعودية!</h1> : 
        <h1 style={{opacity: 0, position: 'absolute'}}>Do you have real estate for sale or rent in Saudi Arabia!</h1>  
      }
      
      {mediumMatches ? (
        <StoriesData />
      ) : mapView == "list" ? (
        <StoriesData />
      ) : null}
      {mediumMatches ? <Spotlight /> : mapView == "list" ? <Spotlight /> : null}
      {/* <FilterBar /> */}
      <PropertyWithFilter />
      {/* <DownloadApp />
      <KeyFeatures /> */}
    </React.Fragment>
  );
}

Home.getLayout = withLayout("default");

export async function getStaticProps(context) {
  return {
    props: {
      ...(await serverSideTranslations(context.locale, ["common"])),
      lang: context.locale
    },
  };
}
